import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import styles from './layout.module.css';
import Header from '../components/header';

import favicon16 from '../../static/favicon-16x16.png';
import favicon32 from '../../static/favicon-32x32.png';
import appleTouchIcon from '../../static/apple-touch-icon.png';
import chromeIcon192 from '../../static/android-chrome-192x192.png';
import chromeIcon512 from '../../static/android-chrome-512x512.png';

const TemplateWrapper = ({ children, coverImage }) => (
  <>
    <div className={styles.page}>
      <Helmet
        htmlAttributes={{
          lang: "nl"
        }}
        title="Silbar"
        meta={[
          { name: 'description', content: 'Silbar' },
          { name: 'keywords', content: 'silbar, blog, verhaal, short story' },
          { name: "theme-color", content: "#222" },
          { name: "color-scheme", content: "light dark" }
        ]}
        link={[
          { rel: "apple-touch-icon", sizes: "180x180", href: `${appleTouchIcon}` },
          { rel: "icon", type: "image/png", sizes:"32x32", href:`${favicon32}` },
          { rel: "icon", type: "image/png", sizes:"16x16", href:`${favicon16}` },
          { rel: "icon", type: "image/png", sizes:"192x192", href:`${chromeIcon192}` },
          { rel: "icon", type: "image/png", sizes:"512x512", href:`${chromeIcon512}` },
          { rel: "manifest", href:"/site.webmanifest" }
        ]}
      />
      <Header coverImage={coverImage} />
      {children}
    </div>
  </>
);

TemplateWrapper.propTypes = {
  children: PropTypes.object,
  coverImage: PropTypes.object
};

export default TemplateWrapper;
