import React from 'react';
import { Link } from 'gatsby';
import styles from './header.module.css';
import BackgroundImage from 'gatsby-background-image';

const Header = ({ coverImage }) => (
  <>
    <BackgroundImage
      Tag="header"
      className={styles.header}
      fluid={coverImage}
    >
      <h1 className={styles.siteTitle}>
        <Link to="/" className={styles.siteTitleLink}>
          Silbar
        </Link>
      </h1>
      <h2 className={styles.motto}>...Silver linings...</h2>
    </BackgroundImage>
  </>
);

export default Header;
